import React, {useContext} from "react"
import Layout from "../components/layout/layout"
import {SiteContext} from "../context/site-context";

const NotFoundPage = () => {

  const siteContext = useContext(SiteContext);
  const i18next = siteContext.i18next;

  return(
    <Layout>
      <div className="page-404">
        <h1>{i18next.t('Page not found')}</h1>
        <p>{i18next.t('Sorry about that.')}</p>
      </div>
    </Layout>
  );
};

export default NotFoundPage
